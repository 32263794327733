import * as React from "react";
import { SimilarDestinationsPropertiesProps } from "./typings";
import { SimilarDestination, SimilarDestinationViews } from "@shared-ui/retail-destination-content-destination-guide";
import { UitkSpacing } from "@egds/react-core/spacing";
export const SimilarDestinationsProperties = (props: SimilarDestinationsPropertiesProps) => {
  const { context, templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const { view = SimilarDestinationViews.SINGLE_DESTINATION } = templateComponent?.config || {};

  return (
    <UitkSpacing margin={{ block: "six" }}>
      <div>
        <SimilarDestination
          skipSsr
          inputs={{
            similarDestinationsCriteria: {
              destinationId: context.searchContext.location.id.toString(),
              includeOrigin: true,
            },
          }}
          similarDestinationsView={view}
        />
      </div>
    </UitkSpacing>
  );
};

export default SimilarDestinationsProperties;
